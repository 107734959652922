$( document ).ready(function() {
  $('.slider').slick({
    autoplay: true,
    speed: 800,
    lazyLoad: 'progressive',
    arrows: true,
    dots: false,
    prevArrow: '<div class="slick-nav prev-arrow"><i></i><svg><use xlink:href="#circle"></svg></div>',
    nextArrow: '<div class="slick-nav next-arrow"><i></i><svg><use xlink:href="#circle"></svg></div>',
  }).slickAnimation();

  $('.product-list').slick({
    autoplay: false,
    speed: 300,
    slidesToShow: 5,
    slidesToScroll: 3,
    arrows: true,
    dots: false,
    prevArrow: '<div class="slick-nav prev-arrow"><i></i><svg><use xlink:href="#circle"></svg></div>',
    nextArrow: '<div class="slick-nav next-arrow"><i></i><svg><use xlink:href="#circle"></svg></div>',
    responsive: [
      {
        breakpoint: 960, // tablet breakpoint
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 1400, // tablet breakpoint
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      }
    ]
  }).slickAnimation();

  $('.product-list-min').slick({
    autoplay: false,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: true,
    dots: false,
    prevArrow: '<div class="slick-nav prev-arrow"><i></i><svg><use xlink:href="#circle"></svg></div>',
    nextArrow: '<div class="slick-nav next-arrow"><i></i><svg><use xlink:href="#circle"></svg></div>',
    responsive: [
      {
        breakpoint: 960, // tablet breakpoint
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
    ]
  }).slickAnimation();


  $('.product-images-for').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: '<div class="slick-nav prev-arrow"><i></i><svg><use xlink:href="#circle"></svg></div>',
    nextArrow: '<div class="slick-nav next-arrow"><i></i><svg><use xlink:href="#circle"></svg></div>',
    fade: true,
    asNavFor: '.product-images-nav'
  });
  $('.product-images-nav').slick({
    slidesToShow: 4,
    slidesToScroll: 1,
    asNavFor: '.product-images-for',
    dots: false,
    arrows: false,
    focusOnSelect: true
  });

  $('.slick-nav').on('click touch', function(e) {

    e.preventDefault();

    let arrow = $(this);

    if(!arrow.hasClass('animate')) {
      arrow.addClass('animate');
      setTimeout(() => {
        arrow.removeClass('animate');
      }, 1600);
    }

  });
})