// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
require("@popperjs/core")

// Import the specific modules you may need (Modal, Alert, etc)
import { Tooltip, Popover } from "bootstrap"

// The stylesheet location we created earlier



Rails.start()
// Turbolinks.start()
ActiveStorage.start()

import "bootstrap"
var jQuery = require('jquery')
global.$ = global.jQuery = jQuery;
import "slick-animation"
import "slick-carousel"
require("packs/slick")
import toastr from 'toastr'
global.toastr = toastr
require("../stylesheets/application")



require("trix")
require("@rails/actiontext")

document.addEventListener('scroll', function() {
  var menu = document.querySelector('.category-mobile');
  var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  
  if (scrollTop > menu.offsetTop) {
    menu.classList.add('menu-scroll');
  } else {
    menu.classList.remove('menu-scroll');
  }
});

document.addEventListener('cocoon:after-insert', function (e, insertedItem) {
  var newObjectId = new Date().getTime();
  $(insertedItem).find('.dynamic-rich-text').attr('id', "rich_text_body_" + newObjectId);
});








